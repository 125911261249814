<template>
  <div>
    <div class="row row-equal">
      <div class="flex">
        <h3 class="display-3">Liste des Programmes</h3>
      </div>
      <div class="flex">
        <va-button to="/admin/create_program" icon="create" class="mr-4 mb-2">Nouveau Programme</va-button>
      </div>
    </div>
    <div class="row row-equal">
      <div class="flex md12 lg12">
        <va-card>
          <va-input class="flex mb-2 md6 xs12" placeholder="Filter..." v-model="filter"/>
          <va-data-table :items="tours" :filter="filter" :per-page="perPage" :current-page="currentPage"
            :columns="columns">
            <template #cell(id)="{ value }"><va-button size="small" :to="`programs/update/${ value }`">Modifier</va-button></template>
            <template #bodyAppend>
              <tr>
                <td colspan="8">
                  <div class="table-example--pagination">
                    <va-pagination v-model="currentPage" input :pages="pages" @filtered="filtered = $event.items"/>
                  </div>
                </td>
              </tr>
            </template>
          </va-data-table>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "list",
  data() {
    return {
      tours: [],
      filtered: [],
      filter: '',
      currentPage: 1,
      perPage: 20,
      columns: [
        { key: 'id', sortable: true},
        { key: 'operator_name', sortable: false},
        { key: 'title', sortable: false},
        { key: 'status', sortable: false},
        { key: 'remaining_trips_count', sortable: true},
        { key: 'total_trips_count', sortable: true},
      ]
    };
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== 0)
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length
    },
  },
  async created() {
    const data = await this.axios.get(`/api/admin/operators/tours`)
    console.log(data)
    this.tours = data.data
    this.filtered = data.data
  }
}
</script>

<style scoped>

</style>
